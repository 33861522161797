<template>
    <div class="col-12 col-xl-5 offset-xl-1">
        <BlckAdvisor />
        <BlckMessagesList/>
    </div>
</template>

<script>
import BlckAdvisor from './BlckAdvisor.vue'
import BlckMessagesList from './BlckMessagesList.vue'

export default {
    components: {
        BlckAdvisor,
        BlckMessagesList
    }
}
</script>