<template>
    <div v-if="dataLoadingMask">
        <span v-if="loadingText">{{ loadingText }}</span>
        <div class="spinner-border ms-3" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div v-else-if="!noSlot"><slot></slot></div>
</template>

<script>
export default {
    props: {
        dataLoadingMask: {
            type: Number,
            default: 0
        },
        loadingText: {
            type: String,
            default: null
        },
        noSlot: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style scoped>
  .spinner-border { vertical-align: middle; }
</style>
