<template>
  <div class="border border-color-vio block rounded shadow p-3 mb-5 bg-white rounded" v-if="messages.length != 0">
    <h4 class=" text-center content-blocks-title"><strong>{{ Block_Title_PM }}</strong>
    </h4>
    <hr/>
    <div
        v-for="message in messages"
        v-bind:key="message.id"
        class="row px-3">
      <div class="col-12 col-sm-3">
        <p class="mp-date">{{ formatDate(message.date * 1000) }}</p>
      </div>
      <div class="col-12 col-sm-9">
        <p class="support-contact-infos-content-no-margin mp-title px-3 py-1"
           v-html="message.message"></p>
      </div>
    </div>
  </div>
  <DataLoading :dataLoadingMask="loading" :noSlot="true"/>
</template>

<script>
import {mapActions} from "vuex";
import emitter from '../../events/emitter.js';
import formatDateMixin from '../../mixins/formatDateMixins';
import {jsonToHtml} from '../../utils/string.js';
import DataLoading from "../Common/DataLoading";

export default {
  components: {
    DataLoading
  },
  mounted() {
    emitter.on('messages-refresh', () => {
      this.getData();
    });
    this.getData();
  },
  data() {
    return {
      Block_Title_PM: "MESSAGES ENVOYÉS",
      messages: [],
      loading: 0
    };
  },
  methods: {
    ...mapActions('message', ['getMessagesHistory']),
    getData() {
      this.loading = 1;
      this.getMessagesHistory().then(messages => {
        this.messages = messages.slice(0, 5).map((value) => {
          if (value.message.includes('%20')) {
            // if function `escape` was used to message we need to `unescape` it
            return {...value, message: unescape(value.message)};
          } else {
            // if message contains \n \r or \t
            return {...value, message: jsonToHtml(value.message)};
          }
        });
      }).finally(() => this.loading = 0 );
    }
  },
  mixins: [formatDateMixin]
}
</script>
<style scoped lang="scss">
@import '../../assets/css/colors.scss';

.support-contact-infos-content-no-margin {
  min-height: 35px;
}

.date-msg {
  background-color: $color-message-envoye;
}
</style>
