<template>
  <MainLayout
    :content_title="Block_Title">
    <template v-slot:head>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css" integrity="sha512-1ycn6IcaQQ40/MKBW2W4Rhis/DbILU74C1vSrLJxCq57o941Ym01SwNsOMqvEBFlcgUa6xLiPY/NS5R+E6ztJQ==" crossorigin="anonymous" referrerpolicy="no-referrer" />
    </template>
    <template v-slot:default>            
      <BlckMyAdvisor />
      <BlckSendPM />
    </template>
  </MainLayout>
</template>

<script>
import BlckMyAdvisor from "../components/Mon-Conseiller/BlckMyAdvisor.vue"
import BlckSendPM from "../components/Mon-Conseiller/BlckSendPM.vue"
import MainLayout from "../layouts/main.layout";

import store from "@/store";
export default {
  page: {
    title: "Home",
    meta: [{ name: "Home page"}],
  },
  components: {
    MainLayout,
    BlckMyAdvisor,
    BlckSendPM

  },
  data() {
    return {
      Block_Title : "MON CONSEILLER",
      //to fix and update
      user: store.user,
    };
  },
  methods: {
  },
};
</script>
