export default {
    methods: {
        /**
         * 
         * @param {int} dateString timestamp en ms
         * @returns {String}
         */
        formatDate(dateString) {
            const date = new Date(dateString);
                // Then specify how you want your dates to be formatted
            return new Intl.DateTimeFormat('default', {dateStyle: 'short'}).format(date);
        },
        /**
         * 
         * @param {String} dateString e.g.:2021-10-14 16:40:00
         * @returns {String}
         */
        formatDateString(dateString) {
            if (typeof dateString === "string") {
                const components = dateString.split(" ");
                if (components.length === 2) {
                    const dateComponents = components[0].split("-");
                    if (dateComponents.length === 3) {
                        return `${dateComponents[2]}/${dateComponents[1]}/${dateComponents[0]} ${components[1]}`;
                    }
                }
            }
            return dateString;
        }
    }
}
